@import "./fonts.css";

:root {
  --text: #443a37;
  --box-shadow: #443a3780;
  --light-box-shadow: #443a3740;
  --border-color: #443a3720;
  --border-medium: #443a3780;
  --gold: #d0a057;
  --background: #F5F4F0;
  --serif: "Albertus Nova";
  --sans-serif: "Soehne", Helvetica Neue, Helvetica, Arial, sans-serif;
}

* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

body {
  font-family: var(--sans-serif);
  background: var(--background);
  color: var(--text);
  margin: 0;
  padding-bottom: 20px;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

body.no-transition {
  transition: none;
}

body.hide > #profile {
  transform: scale(1.1);
  opacity: 0;
}

h1,
h2,
h3 {
  font-size: 1em;
  font-weight: 500;
  margin: 0;
  letter-spacing: -0.2px;
}

h2 {
  text-align: center;
  margin-bottom: 50px;
}

#top > div {
  display: inline-block;
  vertical-align: top;
}

#bio h2 + p {
  margin-top: 40px;
}

#hedcut {
  width: 250px;
  vertical-align: top;
  margin-top: 10px;
}

.inline-block {
  display: inline-block;
}

#profile {
  transform-origin: top;
  margin: 0 auto;
  transition: all 0.3s ease;
}

body.no-transition > #profile {
  transition: none;
}

.section {
  max-width: 600px;
  margin: auto;
}

#bio {
  max-width: 900px;
  padding-left: 0px;
  padding-right: 0px;
  margin: 100px auto;
}

#bio .contact {
  opacity: 0.5;
}

.bio-text {
  max-width: 620px;
  margin-right: calc(-15px);
  margin-left: 40px;
}

#projects,
#experiments {
  max-width: 1100px;
  margin: 40px auto;
}

.section {
  margin-top: 100px;
  margin-bottom: 100px;
}

p {
  font-size: 1.1em;
  font-family: 'Inconsolata', monospace;
  /* otherwise emojis will make lines with them taller. */
  line-height: 28px;
  margin-top: 5px;
  margin-bottom: 0px;
}

#bio p {
  margin: 20px 0px;
}

#bio > p:last-of-type {
  margin-bottom: 0px;
}

p i {
  letter-spacing: -0.3px;
}

.work-section {
  margin: 50px 0px;
  position: relative;
}

.work-logo {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin: 0px 10px 0px 0px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.work-description {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 60px - 4px);
}


.work-description .h-55 {
  height: 25px;
}

.work-description .h-60 {
  height: 30px;

}

.work-description .h-75 {
  height: 35px;
  margin-bottom: -10px;
}

.work-description .h-75--zulip {
  margin-bottom: -7px;
}

#experiments .work-description .h-55,
#experiments .work-description .h-60,
#experiments .work-description .h-75 {
  margin-top: 0px;
}


.work-description a.site-link {
  text-decoration: none;
  color: #d09945;
  font-weight: 400;
  display: block;
  position: relative;
  z-index: 1;
  margin-top: 0px;
}

.work-description p {
  margin: 20px 0px;
}

.work-description p:last-of-type {
  margin-bottom: 0px;
}

p.indent {
  margin-left: 20px;
}

.work-description .position {
  font-weight: 400;
  opacity: 0.5;
}

.sign {
  display: inline-block;
  padding: 20px;
}

.sign svg {
  fill: #fff;
}

.sign > div:not(:first-of-type) {
  margin-top: 5px;
}

.sign > div.s-letter {
  background: #ecc141;
  height: 52px;
  width: 52px;
  margin: 0 2px 10px 2px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: top;
}

.s-letter svg  {
  position: relative;
  top: 1px;
  left: -1px;
  fill: #212020;
}

.s-letter--q svg {
  position: relative;
  top: -2px;
  left: -2px;
}

.sign > div.s-letter--grey {
  background: #9c9595
}

.sign > div.s-letter--grey svg {
  fill: #fff;
  left: -2px;
}

a {
  color: inherit;
}

#experiments .work-description {
  width: calc(100% - 500px);
}

#experiments .work-demo {
  display: inline-block;
  vertical-align: top;
  margin-left: -4px;
  width: calc(100% - 650px);
}

#experiments .work-demo--flex {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.work-demo#fontDemo > div {
  width: 450px;
  position: relative;
  background: #1f2126;
}

#bitArrayDemo > div,
#stochasticMarketDemo > div,
#metaDemo > div,
#fontDemo > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  height: 100%;
  background: #ffffff80;
}

#bitArrayDemo img {
  width: 350px;
}

.work-demo .explanation {
  position: absolute;
  top: calc(100% + 5px);
  opacity: 0.5;
  font-size: 0.8em;
  left: 0%;
  width: 100%;
  text-align: center;
  text-decoration: none;
  color: inherit;
}

#stochasticMarketDemo > div {
  padding: 20px;
}

#stochasticMarketDemo img {
  width: 410px;
  margin: 60px 0px;
}

#metaDemo img {
  width: 350px;
  margin: 20px 0px;
}

@media (max-width: 1200px) {
  .section {
    padding: 0px 20px;
  }

  #experiments .work-description {
    display: block;
    margin: 20px auto;
    width: 100%;
    max-width: 600px;
  }

  #experiments .work-demo--flex {
    position: static;
    display: block;
    width: 100%;
    margin: auto;
    max-width: 600px;
  }

  #experiments .work-demo--flex > div {
    display: block;
    width: 100%;
    text-align: center;
  }

  #experiments .work-demo--flex#stochasticMarketDemo > div > img {
    width: calc(50% - 40px - 4px);
    margin: 40px 20px;
  }

  #experiments .work-demo--flex#metaDemo > div > img {
    width: calc(80% - 4px);
    margin: 0px 20px;
  }

  #experiments .work-demo--flex#bitArrayDemo > div > img  {
    padding: 0px 0px;
    width: 70%;
  }

  .section {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  h2 {
    margin-bottom: 30px;
  }

  .work-section + .work-section {
    margin-top: 50px;
  }

  .work-section {
    margin: 30px 0px;
  }
}

@media (max-width: 1024px) {
  #bio {
    max-width: 600px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 50px;
  }

  #hedcut {
    max-width: 200px;
    margin-bottom: 20px;
  }

  .bio-text {
    display: block;
    width: 100%;
    text-align: left;
    margin-left: 0px;
  }
}

@media (max-width: 500px) {
  #stochasticMarketDemo.work-demo--flex > div,
  #bitArrayDemo.work-demo--flex > div {
    padding: 20px 0px;
  }


  #experiments .work-demo--flex#stochasticMarketDemo > div > img {
    margin: 20px 20px 20px 10px;
    width: calc(100% - 30px);
  }
}
